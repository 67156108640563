
export default {
  data() {
    return {
      meetingA: true,
      meetingB: true,
      meetingC: true,
      typeComparison: 'density',
      series: [
        {
          name: 'Meeting room A',
          data: [0, 41, 35, 51, 49, 62, 69, 91, 148, 40, 33, 56, 0]
        },
        {
          name: 'Meeting room B',
          data: [0, 70, 37, 19, 14, 16, 68, 77, 100, 51, 49, 62, 0]
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#3b9aed', '#b3c85c', '#f6b70b'],
        stroke: {
          curve: 'straight',
          lineCap: 'butt',
          colors: undefined,
          width: 1.5,
          dashArray: 0
        },
        title: {
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: [
            '9:00',
            '10:00',
            '11:00',
            '12:00',
            '13:00',
            '14:00',
            '15:00',
            '16:00',
            '17:00',
            '18:00',
            '19:00',
            '20:00',
            '21:00'
          ]
        }
      }
    };
  }
};


import SpaceOverview from './components/SpaceOverview';
import OverviewBarChart from './components/OverviewBarChart';
import PowerHour from './components/PowerHour';
import VisitPrediction from './components/VisitPrediction';
import RoomUsage from './components/RoomUsage';
import MultiSpaceComparison from './components/MultiSpaceComparison';
import StoreList from './components/StoreList';
export default {
  components: {
    SpaceOverview,
    OverviewBarChart,
    PowerHour,
    VisitPrediction,
    RoomUsage,
    MultiSpaceComparison,
    StoreList
  },
  data() {
    return {};
  },
  created() {}
};

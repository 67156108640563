
export default {
  data() {
    return {
      series: [
        {
          name: 'Average',
          data: [44, 55, 41, 37, 22, 43, 21]
        },
        {
          name: 'Additional',
          data: [23, 12, 13, 12, 13, 13, 12]
        }
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        colors: ['#888888', '#5189d6'],
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          show: true,
          categories: ['MON', 'TUE', 'WED', 'THU', 'FRI']
        },
        yaxis: {
          show: true
        },
        tooltip: {
          show: false,
          y: {
            formatter: function(val) {
              return val + 'K';
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          show: false,
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      }
    };
  }
};

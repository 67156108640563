
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  props: {
    heatmapmode: {
      type: String,
      required: true,
      default: ''
    },
    setheatmapmode: {
      type: Function,
      required: true,
      default: () => {}
    },
    options: {
      type: Object,
      required: false,
      default: () => {}
    } // options
  },
  data: function() {
    return {
      meetingRoom: 'm1',
      maxVal: 0,
      minVal: 0,
      median: 0,
      daysOfWeek: [
        this.$t('monday'),
        this.$t('tuesday'),
        this.$t('wednesday'),
        this.$t('thursday'),
        this.$t('friday'),
        this.$t('saturday'),
        this.$t('sunday')
      ],
      mode: '',
      loading: false,
      formattedValuesList: [],
      dailyValue: '',
      series: [
        { name: this.$t('monday'), data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
        { name: this.$t('tuesday'), data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
        { name: this.$t('wednesday'), data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
        { name: this.$t('thursday'), data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
        { name: this.$t('friday'), data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
        { name: this.$t('saturday'), data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
        { name: this.$t('sunday'), data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }
      ],
      chartOptions: {
        legend: {
          show: false
        },
        chart: {
          height: 350,
          type: 'heatmap',
          toolbar: {
            show: true,
            tools: {
              download: '<i class="fas fa-download"></i>'
            }
          }
        },

        dataLabels: {
          // enabled: true
          background: {
            enabled: true,
            foreColor: '#ff3046'
          },
          style: {
            fontSize: '14px',
            colors: ['#000000']
          },
          formatter: (val, series) => {
            return this.formattedValuesList[series.seriesIndex][
              series.dataPointIndex
            ];
          }
        },
        xaxis: {
          type: 'category',
          categories: []
        },
        title: {},
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return `
            <q-card class="flex column q-pa-md">
            <div class="tool-tip-yaxis">${w.globals.labels[dataPointIndex]}</div>
                     <div class="flex items-center">
                       <div class="day-label-tool-tip">${w.globals.seriesNames[seriesIndex]}:</div>
                     <label class="text-bold">${this.formattedValuesList[seriesIndex][dataPointIndex]}</label></div>
                </q-card>
            `;
          }
        }
      }
    };
  },

  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingPeriod',
      'getStartingCompared',
      'getEndingCompared'
    ]), // mapGetters
    ...mapState('traffic', ['powerHourData']),
    valueRanges() {
      let intMax = parseInt(this.maxVal);
      let intMin = parseInt(this.minVal);
      let dv = intMax - intMin;
      let arr = [
        intMin,
        parseInt(intMin + parseFloat((dv * 0.1).toFixed(1))),
        parseInt(intMin + parseFloat((dv * 0.2).toFixed(1))),
        parseInt(intMin + parseFloat((dv * 0.3).toFixed(1))),
        parseInt(intMin + parseFloat((dv * 0.4).toFixed(1))),
        parseInt(intMin + parseFloat((dv * 0.5).toFixed(1))),
        parseInt(intMin + parseFloat((dv * 0.6).toFixed(1))),
        parseInt(intMin + parseFloat((dv * 0.7).toFixed(1))),
        parseInt(intMin + parseFloat((dv * 0.8).toFixed(1))),
        parseInt(intMin + parseFloat((dv * 0.9).toFixed(1))),
        intMax
      ];
      return arr;
    }
  }, // computed
  watch: {
    heatmapmode: async function(newValue) {
      this.mode = newValue;
      try {
        this.loading = true;
        await this.fetchPowerHourData({
          startDate: this.getStartingPeriod,
          endDate: this.getEndingPeriod,
          daysOfWeek: this.getDaysOfWeekList,
          storeCodes: this.getStoreCodesList,
          mode: newValue
        });
        this.populateHeatMap();
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: error
        });
      } finally {
        this.loading = false;
      }
    },
    mode: async function(val) {
      this.setheatmapmode(val);
    },
    powerHourData() {
      this.populateHeatMap();
      this.loading = false;
    }
  },
  created: async function() {
    this.chartOptions.chart.toolbar = {
      ...this.chartOptions.chart.toolbar,
      export: {
        svg: {
          filename: `${this.$t('powerhour')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        },
        png: {
          filename: `${this.$t('powerhour')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        },
        csv: {
          filename: `${this.$t('powerhour')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        }
      }
    };
    try {
      this.mode = this.heatmapmode;
      this.loading = true;
      await this.fetchPowerHourData({
        startDate: this.getStartingPeriod,
        endDate: this.getEndingPeriod,
        daysOfWeek: this.getDaysOfWeekList,
        storeCodes: this.getStoreCodesList,
        mode: this.mode
      });
      this.populateHeatMap();
    } catch (error) {
      this.$q.notify({
        type: 'negative',
        position: 'top',
        message: error
      });
    } finally {
      this.loading = false;
    }
  }, //created
  methods: {
    ...mapActions('traffic', ['fetchPowerHourData']),
    populateHeatMap: function() {
      if (this.powerHourData) {
        let dataList = [];
        this.formattedValuesList = [];
        this.series = Object.values(this.powerHourData)
          .map((v, key) => {
            dataList = [...dataList, ...v.map(dat => dat.value)];
            return {
              name: this.$t(Object.keys(this.powerHourData)[key].toLowerCase()),
              data: v.map(dat => dat.value),
              formattedValue: v.map(dat => dat.formattedValue)
            };
          })
          .sort(this.daysOfWeekSorter)
          .reverse();
        this.maxVal = parseInt(Math.max(...dataList)).toFixed(0);
        this.minVal = parseInt(Math.min(...dataList)).toFixed(0);
        this.median = (
          (parseInt(this.maxVal) + parseInt(this.minVal)) /
          2
        ).toFixed(0);
        this.series.map(v => {
          this.formattedValuesList = [
            ...this.formattedValuesList,
            v.formattedValue
          ];
        });
      }

      if (this.powerHourData) {
        let categories = Object.keys(this.powerHourData)
          .sort(
            (x, y) => this.daysOfWeek.indexOf(x) - this.daysOfWeek.indexOf(y)
          )
          .map(v => this.powerHourData[v])[0]
          .map(v => v.hour);

        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            categories: categories
          },
          plotOptions: {
            heatmap: {
              enableShades: false,
              shadesIntensity: 0,
              colorScale: {
                ranges: [
                  {
                    from: this.valueRanges[0],
                    to: this.valueRanges[1],
                    color: '#ebf2fa',
                    name: this.valueRanges[1]
                  },
                  {
                    from: this.valueRanges[1],
                    to: this.valueRanges[2],
                    color: '#d8e5f6',
                    name: this.valueRanges[2]
                  },
                  {
                    from: this.valueRanges[2],
                    to: this.valueRanges[3],
                    color: '#c5d7f1',
                    name: this.valueRanges[3]
                  },
                  {
                    from: this.valueRanges[3],
                    to: this.valueRanges[4],
                    color: '#b2cbed',
                    name: this.valueRanges[4]
                  },
                  {
                    from: this.valueRanges[4],
                    to: this.valueRanges[5],
                    color: '#9ebde8',
                    name: this.valueRanges[5]
                  },
                  {
                    from: this.valueRanges[5],
                    to: this.valueRanges[6],
                    color: '#8bb0e4',
                    name: this.valueRanges[6]
                  },
                  {
                    from: this.valueRanges[6],
                    to: this.valueRanges[7],
                    color: '#78a3df',
                    name: this.valueRanges[7]
                  },
                  {
                    from: this.valueRanges[7],
                    to: this.valueRanges[8],
                    color: '#6596db',
                    name: this.valueRanges[8]
                  },
                  {
                    from: this.valueRanges[8],
                    to: this.valueRanges[9],
                    color: '#5189d6',
                    name: this.valueRanges[9]
                  },
                  {
                    from: this.valueRanges[9],
                    to: this.valueRanges[10],
                    color: '#3e7cd2',
                    name: this.valueRanges[10]
                  }
                ]
              }
            }
          }
        };
      }
    },
    daysOfWeekSorter(x, y) {
      return this.daysOfWeek.indexOf(x.name) - this.daysOfWeek.indexOf(y.name);
    }
  }
};

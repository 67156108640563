
export default {
  data: function() {
    return {
      typeComparison: 'hourly',
      series: [
        {
          name: 'PRODUCT A',
          data: [44, 55, 41, 67, 22, 43, 21, 49]
        },
        {
          name: 'PRODUCT B',
          data: [13, 23, 20, 8, 13, 27, 33, 12]
        }
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          stackType: '100%'
        },
        plotOptions: {
          bar: { columnWidth: '20%' }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
                markers: {
                  radius: 0,
                  width: 16,
                  height: 6
                }
              }
            }
          }
        ],
        xaxis: {
          categories: [
            '9am',
            '10am',
            '11am',
            '12am',
            '1pm',
            '2pm',
            '3pm',
            '4pm',
            '5pm',
            '6pm',
            '7pm',
            '8pm',
            '9pm'
          ]
        },
        fill: {
          opacity: 1
        },
        colors: ['#888888', '#f5f5f5'],
        legend: {
          position: 'right',
          offsetX: 0,
          offsetY: 50,
          markers: {
            radius: 0,
            width: 16,
            height: 6
          }
        }
      }
    };
  }
};


export default {
  data() {
    return {
      meetingParticipants: [21, 23, 40, 12, 4],
      meetingRooms: [48, 27, 19, 2, 4],
      meetingParticipantsOptions: {
        chart: {
          width: 380,
          type: 'pie'
        },
        labels: ['8 person', '4 person', '10 person', '2 person', '5 person'],
        legend: {
          show: false
        },
        colors: ['#7585a2', '#f7c739', '#eb7e65', '#73a0fa', '#73deb3'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }
        ]
      },
      meetingRoomOptions: {
        chart: {
          width: 380,
          type: 'pie'
        },
        labels: [
          'Large Meeting Room',
          'Medium meeting room',
          'Small meeting room',
          'Extra large meeting room',
          'Cabinet'
        ],
        legend: {
          show: false
        },
        colors: ['#7585a2', '#f7c739', '#eb7e65', '#73a0fa', '#73deb3'],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }
        ]
      }
    };
  }
};
